/*** TailwindCSS **************************************************************/

/* TailwindCSS base token. */
@tailwind base;

/* TailwindCSS components. */
@tailwind components;

/* TailwindCSS utils. */
@tailwind utilities;

/*** Default ******************************************************************/

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/*** ScrollBars ***************************************************************/

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    @apply bg-[#f1f1f1];
}

/* Handle */
::-webkit-scrollbar-thumb {
    @apply bg-[#888];
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-[#555];
}

.menu-user.userview {
    background-image: linear-gradient(45deg, rgb(0 144 223 / 30%), 30%, transparent);
}

.menu-user.userview .user-icon {
    box-shadow: 0 0 0px 2px rgb(0 144 223 / 80%);
}

.admin-search-content {
    width: 26rem;
    max-width: 100%;
}

.modal-content {
    min-width: 700px;
}

@media print {

    #sidebar-menu,
    #sub-header,
    #layout-detail {
        display: none;
    }

    .border-dashed {
        border: none;
    }

    [as="button"] {
        display: none;
    }
}

#sidebar-menu nav>ul>li ul {
    font-size: 0.875rem;
}
